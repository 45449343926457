import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class BaseCrudService<T> {

  private readonly APIUrl = this.getResourceUrl();

  constructor(protected readonly httpClient: HttpClient) {
  }

  abstract getResourceUrl(): string;

  toServerModel(entity: T): any {
    return entity;
  }

  fromServerModel(json: any): T {
    return json;
  }

  getList(index: number, page: number): Observable<T[]> {
    const params = new HttpParams()
      .set('limit', index.toString())
      .set('offset', page.toString());

    return this.httpClient.get<T[]>(`${this.APIUrl}?${params.toString()}`)
      .pipe(map((list) => list.map((item) => this.fromServerModel(item))));
  }

  get(id: string | number): Observable<T> {
    return this.httpClient.get<T>(`${this.APIUrl}/${id}`)
      .pipe(map((json) => this.fromServerModel(json)));
  }

  add(resource: T): Observable<any> {
    return this.httpClient.post(this.APIUrl, this.toServerModel(resource));
  }

  delete(id: string | number): Observable<any> {
    return this.httpClient.delete(`${this.APIUrl}/${id}`);
  }

  update(resource: T) {
    return this.httpClient.put(this.APIUrl, this.toServerModel(resource));
  }

}
