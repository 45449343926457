import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundModule } from '@shell/ui/not-found/not-found.module';
import { LayoutModule } from './ui/layout/layout.module';


const MODULES = [
  LayoutModule,
  NotFoundModule,
]

@NgModule({
  imports: [
    CommonModule,
    ...MODULES,
  ],
  exports: [
    RouterModule,
    ...MODULES
  ],
})
export class ShellModule { }
