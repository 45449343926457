<div class="container-fluid">
  <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
  <ng-content></ng-content>
</div>

<ng-template #headerTemplate>
  <div class="header">
    <div class="logo-wrapper">
      <img [src]="constants.applicationLogoUrl" alt="" class="img-fluid logo"/>
    </div>
  </div>
</ng-template>
