import { Component, OnInit } from '@angular/core';
import { SeoService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isLoggedIn$!: Observable<boolean>;

  constructor(
    private readonly seoService: SeoService,
  ) {
  }

  ngOnInit(): void {
    console.log("Entring the application");
    this.runGlobalServices();
  }

  private runGlobalServices(): void {
    this.seoService.init();
  }
}
