export const API_URL_UTIL = {
  events: {
    detail: 'public/events',
  },
  posts: {
    detail: 'public/post',
  },
  profile: {
    detail: 'user-profile-details',
  },
};
