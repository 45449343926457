import { faArrowLeft, faCity, faColumns, faEnvelope, faFileAlt, faPhoneAlt, faSignal, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";

export const faIcons = {
  faSpinner,
  faCity,
  faColumns,
  faSignal,
  faFileAlt,
  faEnvelope,
  faPhoneAlt,
  faUser,
  faArrowLeft
}

