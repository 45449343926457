// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { versions } from './versions';

export const environment = {
  versions,
  production: false,
  apiUrl: 'https://dev-api.breakersparadise.com/api',
  persistUserSession: true, // if the user login session needs to be persisted across browser tabs
  enableOtpBasedLogin: false, // true means user will be able to login with otp else will have to login with email & password only
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
