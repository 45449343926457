export const ROUTER_UTILS = {
  config: {
    base: {
      root: '/',
    },
    events: {
      root: 'event',
      detail: ':eventId',
    },
    posts: {
      root: 'post',
      detail: ':postId',
    },
    profiles: {
      root: 'profile',
      detail: ':userId',
    },
    errorResponse: {
      notFound: '404',
    },
  },
};
