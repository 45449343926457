import { environment } from "@env/environment";

export enum StorageItem {
  AuthToken = 'authToken',
}

export const getItem = (itemName: StorageItem): unknown | null => {
  const item = getStorage().getItem(itemName);
  return item ? JSON.parse(item) : null;
};

export const setItem = (itemName: StorageItem, value: unknown): void => {
  getStorage().setItem(itemName, JSON.stringify(value));
};

export const removeItem = (itemName: StorageItem): void => {
  getStorage().removeItem(itemName);
};

const getStorage = () => {
  return environment.persistUserSession ? localStorage : sessionStorage;
}
