import {
  HttpErrorResponse,
  HttpEvent, HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@core/services/common.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerMessage } from '../../@shared/models';
@Injectable({ providedIn: 'root' })
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly commonService: CommonService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler,): Observable<HttpEvent<unknown>> {
    this.commonService.isApiCallInProgress$.next(true);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.commonService.isApiCallInProgress$.next(false);
        const errorResponse: ServerMessage = error.error;
        if ([401].includes(error.status)) {
          return throwError(error);
        } else if (error.status === 500) {
          console.error(error);
          return throwError(error);
        } else {
          return throwError(error);
        }
      }),
    );
  }
}
