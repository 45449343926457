import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { NotFoundComponent } from '@shell/ui/not-found/not-found.component';


const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTER_UTILS.config.events.root
  },
  {
    path: ROUTER_UTILS.config.events.root,
    loadChildren: async () => (await import('@pages/event/event.module')).EventModule,
  },
  {
    path: ROUTER_UTILS.config.posts.root,
    loadChildren: async () => (await import('@pages/post/post.module')).PostModule,
  },
  {
    path: ROUTER_UTILS.config.profiles.root,
    loadChildren: async () => (await import('@pages/profile/profile.module')).ProfileModule,
  },
  {
    path: '**',
    loadChildren: async () => (await import('@shell/ui/not-found/not-found.module')).NotFoundModule,
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
