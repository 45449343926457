import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/@shared/constants/app.constants';
import { faIcons } from './font-awesome-icon.utils';
import { ROUTER_UTILS } from './router.utils';

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnDestroy {

  destroy$ = new Subject();
  path = ROUTER_UTILS.config;
  constants = Constants;
  faIcons = faIcons;

  ngOnDestroy() {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
